$fontPath: '/furniture/js/dist/angular/assets/fonts/material-icons/';

@font-face {
   font-family: 'Material Icons';
   font-style: normal;
   font-weight: 400;
   src: local('Material Icons'),
       local('MaterialIcons-Regular'),
      url(#{$fontPath}MaterialIcons-Regular.woff2) format('woff2'),
      url(#{$fontPath}MaterialIcons-Regular.woff) format('woff'),
      url(#{$fontPath}MaterialIcons-Regular.ttf) format('truetype');
}

// Usage: <span class="material-icon">some_value</span> where some_value is
// an Icon Font value from https://fonts.google.com/icons?selected=Material+Icons
.material-icon {
   font-family: 'Material Icons';
   font-weight: normal;
   font-style: normal;
   font-size: 1.5rem;
   display: inline-block;
   line-height: 1;
   text-transform: none;
   letter-spacing: normal;
   word-wrap: normal;
   white-space: nowrap;
   direction: ltr;

   /* Support for all WebKit browsers. */
   -webkit-font-smoothing: antialiased;
   /* Support for Safari and Chrome. */
   text-rendering: optimizeLegibility;

   /* Support for Firefox. */
   -moz-osx-font-smoothing: grayscale;
}


@import '../../../../scss/_palette';
@import '~@angular/material/theming';
@import 'themes/_bbThemeBasedOn18';

@include angular-material-theme($bb-light-theme);

.darkMode {
   @include angular-material-color($bb-dark-theme);
}

// https://v11.material.angular.io/guide/typography
$custom-typography: mat-typography-config(
   $font-family: $brandFont,
   $body-1: mat-typography-level(
      12px, normal, 400
   ),
   $subheading-2: mat-typography-level(
      12px, normal, 400
   )
);

@include mat-core($custom-typography);

mat-form-field {
   .mat-form-field-wrapper {
      width: 100%;
   }

   .mat-form-field-label {
      text-transform: none; // Overrides newstyle.scss label uppercase
   }
}

.near-to-trigger-tooltip {
   position: relative;
   top: -20px;
}

// Reduce the width at which the tab header labels
// overflow and the "<" + ">" buttons show
mat-tab-header {
   .mat-tab-labels {
      justify-content: space-around;
   }

   .mat-tab-label {
      min-width: unset;
      justify-content: space-around;
   }
}

bb-click-to-edit {
   mat-form-field {
      margin: -0.25em 0;

      .mat-form-field-wrapper {
         padding-bottom: 0;
      }
   }
}


/* Global dialog styles */
.wide-dialog {
   width: 80vw;
}

@import 'mixins';

// Official blackbird brand PDF colours - these should only be used in
// the _palette and _bootstrap_variables files, any use elsewhere
// should be via a purpose-named definition below

$blackbirdBlack: #1e1e1e;
$blackbirdPurple: #4a3651;
$blackbirdClaret: #641f45;
$blackbirdRed: #b71234;
$blackbirdOrange: #e37222;
$blackbirdYellow: #f0ab00;
$blackbirdWhite: #ffffff;

// New Color Set CC-508
$sidebarBackgroundColour: #080809;
$iconsLhsColour: #caccce;
$claretReplacement:#303136;
$hoverColour:#383a3e;

// Angular Material Theme18 Colours
$bbPrimary100: #ccc8c8;
$bbPrimary500: #554949;

$bbAccent100: #ccd6cc;
$bbAccent500: #567656;

$bbRedAlert100: #f9b6b3;
$bbRedAlert500: #e00b00;
$bbRedAlert600: #d10a00;
$bbOrangeAlert: #e38233;

$bbGreenAlert500: #157a29;

// 9 shades of grey
$greyLowZoneI: black;
$greyLowZoneII: hsla(0, 0%, 12%, 1);
$greyLowZoneIII: hsla(0, 1%, 22%, 1);
$greyMidZoneIV: hsla(0, 1%, 34%, 1);
$greyMidZoneV: hsla(0, 1%, 49%, 1);
$greyMidZoneVI: hsla(0, 1%, 63%, 1);
$greyHighZoneVII: hsla(0, 3%, 83%, 1);
$greyHighZoneVIII: hsla(0, 4%, 95%, 1);
$greyHighZoneIX: white;

$gray: grey;
$gray2e: #2e2e2e;
$gray3d: #3d3d3d;
$gray4: #444444;
$gray57: #575757;
$graya7: #a7a7a7;
$grayc: #cccccc;
$grayd4: #d4d4d4;
$grayd7: #d7d7d7;
$grayd9: #d9d9d9;
$graydf: #dfdfdf;
$graye2: #e2e2e2;
$grayf2: #f2f2f2;
$grayf9: #f9f9f9;
$grayf0: #f0f0f0;
$grayfb: #fbfbfb;
$gray71: #717171;
$gray9: #999999;
$grayeb: #ebebeb;
$graye: #eeeeee;
$grayff: #ffc7001a;
$graye0: #e0e0e0;
$white: #ffffff;

$buttonNormalFontColor: $greyHighZoneVII;
$buttonHoverFontColor: $greyMidZoneVI;
$buttonActiveFontColor: $greyHighZoneVII;
$buttonFocusFontColor: $greyHighZoneVIII;
$boxShadowMenu: rgba(87, 83, 83, 0.1);
//////////////////////////////////

$normalTextColor: $blackbirdBlack;
$editorSidebarBackground: $gray57;
$textGray: #6e7480;
$headerGray: #707682;

$popoverBorder: rgba(0, 0, 0, 0.2);

$sidebarBackground: $sidebarBackgroundColour;
$sidebarBorder: rgba($sidebarBackgroundColour, 0.3);
$sidebarItemSeparatorColor: $gray3d;
$sidebarItemSeparatorColor2: $gray2e;
$sidebarIconColor: $iconsLhsColour;
$sidebarTextColor: $iconsLhsColour;

$defaultContextColour: $hoverColour;
$cloudContextColour: $hoverColour;
$sitegroupContextColour: $blackbirdOrange;
$siteContextColour: $blackbirdOrange;
$accountContextColour: $claretReplacement;

// Table
$backgroundSemiTransparent: rgba(255, 255, 255, 0.6);
// Dialogs
$dialogHeaderColour: $claretReplacement;
$modalBackgroundColour: rgba(0, 0, 0, 0.2);
$dialogBackgroundColour: $greyHighZoneVIII;
$dialogBorderColour: $greyMidZoneVI;

$notificationAreaBackgroundColour: #fcf8e3;
$notificationAreaTextColour: #8a6d3b;
$notificationAreaBorderColour: darken(adjust-hue($notificationAreaBackgroundColour, -10), 5%);

// Media manager grid of icons
$gridModeButtonActive: $claretReplacement;
$gridThumbnailBackground: $claretReplacement;
$gridThumbnailForeground: lighten($claretReplacement, 10%);
$gridMissingThumbnailBackground: lighten($claretReplacement, 20%);
$gridMissingThumbnailForeground: lighten($claretReplacement, 30%);

// These status colours are mainly used as background
$statusSuccess: #aaec52; //  a light green from screenshot colour swatch.
$statusError: $blackbirdRed;
$statusInfo: $blackbirdBlack;
$statusWarning: $blackbirdOrange;
$statusInProgress: $blackbirdYellow;
$statusToUpload: darken($claretReplacement, 10%);
$statusNormal: $claretReplacement;
$statusQueued: $blackbirdBlack;

// Gradients derived from status colors above
$statusGradientDarkenRate: 10%;
$statusSuccessGradientOrigin: darken(desaturate($statusSuccess, 25%), $statusGradientDarkenRate); // Compensate highly saturated successGreen
$statusSuccessGradientTarget: darken($statusSuccessGradientOrigin, $statusGradientDarkenRate);
$statusErrorGradientOrigin: $statusError;
$statusErrorGradientTarget: darken($statusErrorGradientOrigin, $statusGradientDarkenRate);
$statusInfoGradientOrigin: lighten($statusInfo, 4 * $statusGradientDarkenRate);
$statusInfoGradientTarget: $statusInfo;
$statusWarningGradientOrigin: $statusWarning;
$statusWarningGradientTarget: darken($statusWarningGradientOrigin, $statusGradientDarkenRate);
$statusInProgressGradientOrigin: $statusInProgress;
$statusInProgressGradientTarget: darken($statusInProgressGradientOrigin, $statusGradientDarkenRate);

// Used for rows in tables
$selectedRow: $blackbirdYellow;
$selectedRowHoverBackground: darken($claretReplacement, 10%);
$selectedRowHoverColor: $white;

// Used for buttons
$buttonIconColor: $claretReplacement;
$buttonHoverIconColorLight: white;
$buttonHoverIconColorDark: $graydf;

$placeholderColor: $gray9;

$headerBackground: $white;
$headerBorder: $grayd7;

$breadcrumbText: $textGray;

$scrollBarTrack: #d1d1d1;
$scrollBarThumbColor: $textGray;

$contentBackground: $grayf2;
$socialBackground: $graye;

$primaryPanelTopBorderColor: $graydf;
$detailPanelBackgroundColor: $white;
$detailPanelSelectedBorder: $claretReplacement;
$panelBorderColor: $grayc;

$blackTransparent1: rgba(#000000, 0.1);
$blackTransparent2: rgba(#000000, 0.2);
$blackTransparent3: rgba(#000000, 0.3);
$blackTransparent8: rgba(0, 0, 0, 0.87);
$whiteTransparent6: rgba($white, 0.6);
$whiteTransparent8: rgba($white, 0.8);

$panelBackgroundColor: $blackbirdWhite;
$panelHeaderColor: $claretReplacement;
$panelHeaderBorderColor: $grayd9;

$buttonBackground: $blackbirdWhite;
$buttonColor: $bbPrimary500;
$buttonHighlightedBackground: $bbPrimary500;
$buttonHighlightedColor: $blackbirdWhite;
$inputBorderColor: #d4d6d9;

$formusersBackground: $blackbirdBlack;

$detailPanelBackgroundColor: $blackbirdWhite;

$cardSelectedTitleColor: $buttonHighlightedColor;
$cardSelectedTitleBackground: $buttonHighlightedBackground;
$cardShadowColor: $graydf;
$cardNewBackground: $grayeb;
$cardNewText: $graya7;

$recordButtonColor: $blackbirdRed;
$shareButtonColor: $blackbirdRed;
$footerLinks: $claretReplacement;
$messageBackground: $blackbirdOrange;

//////////////////////////////////

$baseSize: asRem(5px);
@function size($factor) {
   @return $baseSize * $factor;
}
// Align far left and right of content area
$horizontalPageSpacing: size(5);

// Align left and right of detail panel;
$horizontalDetailPanelSpacing: size(3);

//////////////////////////////////

$fontPath: "/furniture/fonts/";

@font-face {
   font-family: DINWeb-Light;
   src: url("#{$fontPath}DINWeb-Light.woff") format("woff");
}
@font-face {
   font-family: DINWeb-Bold;
   src: url("#{$fontPath}DINWeb-Bold.woff") format("woff");
}
$brandFontLight: DINWeb-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
$brandFont: DINWeb, "Helvetica Neue", Helvetica, Arial, sans-serif;
$brandFontBold: DINWeb-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;

// Above are definitely used in Nimbletank graphic design

$black: #000000;

$gray2: #222222;
$gray5: #555555;
$gray6: #666666;
$gray7: #777777;
$gray8: #888888;
$graya: #aaaaaa;
$grayd: #dddddd;
$grayf5: #f5f5f5;
$grayf1: #f1f1f1;

$transparent: transparent;
$whiteTransparent2: rgba($white, 0.2);
$blackTransparent05: rgba($black, 0.05);
$blackTransparent4: rgba($black, 0.4);

$blue: #0000ff;

$fadedPink: #ffefef;
$darkFadedPink: #ffefef;
$fadedGreen: #ccdccc;
$lightRed: #ee0000;
$darkRed: #800000;
$fadeRed: #a94442;
$highlightGreen: #99ff99;

$mobileBlue: #246776;
$mobileGreen: #00a000;
$mobileLightBlue: #5b9baa;
$mobileLighterBlue: #91d1e2;

$userFormGreen: #009900;

$uploadBlue: #246776;
$nodeExploreContentBackgroundGradient1: rgba(31,0,36,0.1);
$nodeExploreContentBackgroundGradient2: rgba(180,64,23,0.1);
$nodeExploreContentBackgroundGradient3: rgba(255,199,0,0.1);
$mediaCardSelectionBlue: #496eb9

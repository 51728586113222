@import './variables.scss';
@import './functions.scss';
@import './mixins.scss';
@import '../../../../scss/palette';
@import '../../../../scss/custom_mat_dialog';

/* You can add global styles to this file, and also import other style files */
html, body {
   height: 100%;
}
body {
   margin: 0;
   font-family: $brandFont;
}

footer {
   padding: size(4) $horizontalPageSpacing;

   a {
      padding-right: size(2);
      margin-right: size(2);
      border-right: 1px solid $panelBorderColor;

      &:last-child {
         border-right: none;
      }
   }
}

.sequence-expansion-pannel > .mat-expansion-panel-content {
   overflow-x: auto;
}
